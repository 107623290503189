import React, { Fragment, useCallback, useEffect, useState } from "react";
//import "../config";
// import { authContainer, onSignInSuccess } from "../services/firebaseAuth";
//import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/auth";
import "firebaseui/dist/firebaseui.js";
import "firebaseui/dist/firebaseui.css";
import LoginUserProfile from "./loginUserProfile";
//const firebase = require('firebase');
const firebaseui = require("firebaseui");

//const firebase = require('firebase');
const ui = new firebaseui.auth.AuthUI(firebase.auth());

function Login() {
  function getCurrentUser() {
    try {
      const jwt = localStorage.getItem("user");
      setUser(JSON.parse(jwt));
      return JSON.parse(jwt);
    } catch (ex) {
      return null;
    }
  }

  function authContainer(container) {
    ui.start(`#${container}`, {
      callbacks: {
        signInSuccessWithAuthResult: onSignInSuccess,
        uiShown: function () {
          console.log(`The widget is rendered. Hide the loader.`);
          // The widget is rendered.
          // Hide the loader.
        },
      },
      signInFlow: "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
      // Other config options...
    });

    //return 'test';
  }

  const onSignInSuccess = (authResult, redirectUrl) => {
    //const jesonUser = JSON.stringify(authResult.user);
    //localStorage.setItem("user", jesonUser);
    //setUser(JSON.parse(jesonUser));
    //console.log(user);
    return false;
  };
  const [currentUser, setUser] = useState();

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      console.log("User is signed in ");
      console.log(user);
      setUser(user);
    } else {
      console.log("No user is signed in ");
      authContainer("firebaseui-auth-container");
    }
  });
  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  };
  return (
    <Fragment>
      {/* {console.log(currentUser)}
      <div id="firebaseui-auth-container">Login</div> */}
      {currentUser && (
        <LoginUserProfile onSignOut={handleSignOut} user={currentUser} />
      )}
      {!currentUser && (
        <div
          className="text-center h1 text-uppercase mt-3"
          id="firebaseui-auth-container"
        >
          Sign in
        </div>
      )}
    </Fragment>
  );
}

export default Login;
