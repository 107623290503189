import firebase from "firebase/app";
const config = {
  firebase: {
    apiKey: "AIzaSyCybC5fQNfn2thySwed_q-df3G1OjKIanE",
    authDomain: "react-test-01-8164b.firebaseapp.com",
    projectId: "react-test-01-8164b",
    storageBucket: "react-test-01-8164b.appspot.com",
    messagingSenderId: "144986001610",
    appId: "1:144986001610:web:6c9adb898d610ba1972433"
  }
}
//firebase.initializeApp(config.firebase)
export default firebase.initializeApp(config.firebase);
//export default config