import { Avatar, Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "25px auto 0 auto",
  },
}));
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
function LoginUserProfile({ user, onSignOut }) {
  console.log(user);
  return (
    <div className="text-center">
      <Paper style={{ height: "100vh" }} variant="outlined">
        <div className="mt-auto">
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={user.displayName}
              src={user.photoURL}
              className={useStyles().large}
            />
          </StyledBadge>
          <Typography component="p">{user.displayName}</Typography>
          <Button href="/login" onClick={onSignOut}>
            Sign Out
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default LoginUserProfile;
