import React, { Fragment } from "react";
import backgroundImg from "./assets/background.jpg";
function FirstWeb() {
  return (
    <Fragment>
      <div
        style={{
          backgroundImage: "url(" + backgroundImg + ")",
          height: "100vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: "25%",
        }}
      />
      <div
        style={{
          backgroundImage: "url(" + backgroundImg + ")",
          height: "100vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          opacity: "100%",
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      />
    </Fragment>
  );
}

export default FirstWeb;
