import React from 'react';
import ReactDOM from 'react-dom';
import "./config";
import './index.css';
import App from './App';
// import config from './config'
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
//import Test from './pages/material-ui/test';
import { SnackbarProvider } from 'notistack';
import 'bootstrap/dist/css/bootstrap.css';
// import FirstWeb from './FirstWeb';
ReactDOM.render(
  <BrowserRouter >
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>

  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
